// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################

var president_sources = [
    'Contains 538 data from https://github.com/fivethirtyeight/data/tree/master/polls.',
  'https://projects.fivethirtyeight.com/polls-page/president_polls.csv'
];

var senate_sources = [
        'Contains 538 data from https://github.com/fivethirtyeight/data/tree/master/polls.',
  "https://projects.fivethirtyeight.com/polls-page/senate_polls.csv",
    "Class II - Senators Whose Terms of Service Expire in 2021: https://www.senate.gov/senators/Class_II.htm"
];

var house_sources = [
            'Contains 538 data from https://github.com/fivethirtyeight/data/tree/master/polls.',
  "https://projects.fivethirtyeight.com/polls-page/house_polls.csv"
];

var senate_columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'firstName', headerName: 'First name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 90,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.getValue('firstName') || ''} ${
        params.getValue('lastName') || ''
      }`,
  },
];

var senate_rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];


module.exports = {
  // these 3 are used to create the tasks lists in TasksCard - Dashboard view
  president_sources,
  senate_sources,
  house_sources,
  senate_rows,
  senate_columns
};
