import React, {useState} from "react";
import {Switch, Route, Redirect, useLocation} from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import {UserProvider} from "contexts/UserContext";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/policiesStyle.js";

import logo from "assets/img/azrius.png";

let ps;


const switchRoutes = (
    <Switch>
        {routes.map((prop, key) => {
            if (prop.layout === "/home") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            }
            else if (prop.layout === "/policies") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            }
            else if (prop.layout === "/data") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            }
            return null;
        })}
        <Redirect from="/home" to="/home/welcome"/>
    </Switch>
);

const useStyles = makeStyles(styles);


export default function Welcome({...rest}) {
    const user = {name: 'Tim', loggedIn: true}

    // styles
    const classes = useStyles();
    // ref to help us initialize PerfectScrollbar on windows devices
    const mainPanel = React.createRef();


    const [userInfo, setUserInfo] = useState(null);

    // states and functions
    // const [image, setImage] = React.useState(bgImage);
    const [color, setColor] = React.useState("blue");
    const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleImageClick = image => {
        //setImage(image);
    };
    const handleColorClick = color => {
        setColor(color);
    };
    const handleFixedClick = () => {
        if (fixedClasses === "dropdown") {
            setFixedClasses("dropdown show");
        } else {
            setFixedClasses("dropdown");
        }
    };
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const getRoute = () => {
        return window.location.pathname !== "/home/maps";
    };
    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };
    // initialize and destroy the PerfectScrollbar plugin
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
            document.body.style.overflow = "hidden";
        }
        window.addEventListener("resize", resizeFunction);
        // Specify how to clean up after this effect:
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
            window.removeEventListener("resize", resizeFunction);
        };
    }, [mainPanel]);
    return (
        <div className={classes.wrapper}>
            <Sidebar
                routes={routes}
                logoText={"Azrius Analytics"}
                logo={logo}
                //image={image}
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
                color={color}

                {...rest}
            />
            <div className={classes.mainPanel} ref={mainPanel}>
                <UserProvider value={user}>
                    <Navbar
                        routes={routes}
                        user={user}
                        handleDrawerToggle={handleDrawerToggle}
                        {...rest}
                    />
                </UserProvider>


                {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                {getRoute() ? (
                    <UserProvider value={user}>
                        <div className={classes.content}>
                            <div className={classes.container}>{switchRoutes}</div>
                        </div>
                    </UserProvider>
                ) : (
                    <UserProvider value={user}>
                        <div className={classes.map}>{switchRoutes}</div>
                    </UserProvider>

                )}
                {getRoute() ? <Footer/> : null}

            </div>
        </div>
    );
}
