import React, {useState} from "react";
import classNames from "classnames";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import {useHistory} from 'react-router-dom';

// core components
import Button from "components/CustomButtons/Button.js";


import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function LoginNavbarLinks(props) {
    const {user, ...rest} = props;
    const history = useHistory();

    const classes = useStyles();
    const [openNotification, setOpenNotification] = React.useState(null);
    const [openProfile, setOpenProfile] = React.useState(null);

    const [userContext, setUserContext] = useState(null);

    const handleClickNotification = event => {
        if (openNotification && openNotification.contains(event.target)) {
            setOpenNotification(null);
        } else {
            setOpenNotification(event.currentTarget);
        }
    };

    const handleCloseNotification = () => {
        alert('handleCloseNotification');
        setOpenNotification(null);
    };

    const handleClickProfile = event => {
        if (openProfile && openProfile.contains(event.target)) {
            setOpenProfile(null);
        } else {
            setOpenProfile(event.currentTarget);
        }
    };

    const viewProfile = () => {
        setOpenProfile(null);
        history.push("/admin/user");
    };

    const isLoggedIn = () => {
        console.log("isLoggedIn" + userContext);
        if (userContext) {
            return userContext.loggedIn;
        } else {
            let savedContext = JSON.parse(localStorage.getItem("userContext"));
            if (savedContext) {
                console.log("savedContext: " + savedContext + " " + savedContext.name + " " + savedContext.loggedIn);

                return savedContext.loggedIn;
            } else {
                return false;
            }
        }
    };

    const updateUserContext = (response) => {
        console.log("updateUserContext");
        console.log(response);
        setOpenProfile(null);
        let user = {"name": response.name, "email": response.email, "loggedIn": true}
        setUserContext(user);
        localStorage.setItem('userContext', JSON.stringify(user));

    };

    const deleteUserContext = (response) => {
        localStorage.removeItem('userContext');
        setOpenProfile(null);
    };


    const handleError = (response) => {
      console.error(response);
    }


    const handleCloseProfile = () => {
        setOpenProfile(null);
    };

    return (
        <div>
            <div className={classes.manager}>
                <Button
                    color={window.innerWidth > 959 ? "transparent" : "white"}
                    justIcon={window.innerWidth > 959}
                    simple={!(window.innerWidth > 959)}
                    aria-owns={openProfile ? "profile-menu-list-grow" : null}
                    aria-haspopup="true"
                    onClick={handleClickProfile}
                    className={classes.buttonLink}
                >

                    <Person className={classes.icons}/>
                    <Hidden mdUp implementation="css">
                        <p className={classes.linkText}>Profile</p>
                    </Hidden>
                </Button>
                <Poppers
                    open={Boolean(openProfile)}
                    anchorEl={openProfile}
                    transition
                    disablePortal
                    className={
                        classNames({[classes.popperClose]: !openProfile}) +
                        " " +
                        classes.popperNav
                    }
                >
                    {({TransitionProps, placement}) => (
                        <Grow
                            {...TransitionProps}
                            id="profile-menu-list-grow"
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom"
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseProfile}>
                                    <MenuList role="menu">
                                        {isLoggedIn() === false ? (
                                            <FacebookLogin
                                            appId="754258275116869"
                                            autoLoad={false}
                                            fields="name,email,picture"
                                            scope="public_profile,email"
                                            render={renderProps => (
                                                <MenuItem
                                                    onClick={renderProps.onClick}
                                                    className={classes.dropdownItem}
                                                >
                                                    Login with Facebook
                                                </MenuItem>
                                            )}
                                            callback={(response) => updateUserContext(response)}
                                        />) : (
                                            [
                                                <MenuItem
                                                    key="profile"
                                                    onClick={viewProfile}
                                                    className={classes.dropdownItem}
                                                >
                                                    User Profile
                                                </MenuItem>,
                                                <Divider key="divider" light/>,
                                                <MenuItem
                                                    onClick={deleteUserContext}
                                                    key="logout"
                                                    className={classes.dropdownItem}
                                                >
                                                    Logout
                                                </MenuItem>
                                              ]
                                            )
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Poppers>

            </div>
        </div>

    );
}
