/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons

import Home from "@material-ui/icons/Home";
// Azrius
import Policy from "@material-ui/icons/Policy";
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";

// core components/views for Admin layout

// Azrius Analytics
import PrivacyPage from "views/Privacy/Privacy.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import WelcomePage from "views/Home/Welcome.js";
import DashboardPage from "views/Dashboard/Dashboard.js";
import Election2020DashboardPage from "views/Dashboard/Election2020Dashboard.js";
import DataDictionary from "views/Data/DataDictionary.js";

const dashboardRoutes = [
{
    path: "/welcome",
    name: "Welcome",
    rtlName: "لوحة القيادة",
    icon: Home,
    component: WelcomePage,
    layout: "/home"
  },
    {
    path: "/election2020",
    name: "Election 2020",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: Election2020DashboardPage,
    layout: "/polls"
  },
    {
    path: "/dashboard",
    name: "Admin",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/dictionary",
    name: "Data Dictionary",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: DataDictionary,
    layout: "/data"
  },
{
    path: "/user",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin"
  },
  {
    path: "/privacy",
    name: "Policies",
    rtlName: "لوحة القيادة",
    icon: Policy,
    component: PrivacyPage,
    layout: "/policies"
  }
];

export default dashboardRoutes;
