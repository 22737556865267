/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Router, Route, Switch, Redirect} from "react-router-dom";


// core components
import Admin from "layouts/Admin.js";
import Home from "layouts/Home.js";
import Data from "layouts/Data.js";
import Polls from "layouts/Polls.js";
import Policies from "layouts/Policies.js";
import RTL from "layouts/RTL.js";

import "assets/css/material-dashboard-react.css?v=1.9.0";

const hist = createBrowserHistory();

ReactDOM.render(
    <Router history={hist}>
        <Switch>
            <Route path="/home" component={Home} />
            <Route path="/data" component={Data}/>
            <Route path="/admin" component={Admin}/>
            <Route path="/polls" component={Polls}/>

            <Route path="/policies" component={Policies}/>
            <Route path="/rtl" component={RTL}/>

            <Redirect from="/" to="/home/welcome"/>
        </Switch>
    </Router>,
    document.getElementById("root")
);
