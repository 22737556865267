import React, {useState, useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {UserConsumer} from "../../contexts/UserContext";
import FacebookLogin from 'react-facebook-login';
import FormDialog from "components/LoginForm/LoginForm"

import Button from "../../components/CustomButtons/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import TableCell from "@material-ui/core/TableCell";


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function DataDictionary(props) {
  const { user, ...rest } = props;

  const onChange = event => {
      console.log( event.target.value);
  };
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [reports, setReports] = useState([]);
  const [userContext, setUserContext] = useState(null);

  const isLoggedIn = () => {
      console.log("isLoggedIn" + userContext);
      if(userContext) {
          return userContext.loggedIn;
      }
      else {
          let savedContext = JSON.parse(localStorage.getItem("userContext"));
          if( savedContext ) {
              console.log("savedContext: "+ savedContext + " " + savedContext.name + " " + savedContext.loggedIn);

              return savedContext.loggedIn;
          }
          else {
              return false;
          }
      }
  };

  const updateUserContext= (response) => {
      console.log("updateUserContext");
      console.log(response);
      let user = {"name": response.name, "email": response.email, "loggedIn": true }
      setUserContext(user);
      localStorage.setItem('userContext', JSON.stringify(user));
  };

  const handleSubscription= (value) => {
      console.log("handleSubscription: " + value);
      let userContext = JSON.parse(localStorage.getItem("userContext"));
      if(userContext != null) {
          if (userContext["reports"] == null) {
              userContext["reports"] = {}
          }
          userContext["reports"][value] = true
          saveUser(userContext)
          console.log(userContext)
          setUserContext(userContext);
      }
  };

  const handleUnsubscribe= (value) => {
      console.log("handleUnsubscribe: " + value);
      let userContext = JSON.parse(localStorage.getItem("userContext"));
      userContext["reports"][value] = false;
      localStorage.setItem('userContext', JSON.stringify(userContext));
      setUserContext(userContext);
  }

  const isUserSubscribed= (value) => {
      console.log("isUserSubscribed: " + value);
      let userContext = JSON.parse(localStorage.getItem("userContext"));
      console.log(userContext)
      if(userContext != null && userContext["reports"] != null && userContext["reports"][value] != null) {
          return userContext["reports"][value]
      }
      else {
          return false;
      }
  };

  const saveUser= (user) => {
      localStorage.setItem('userContext', JSON.stringify(user));
      fetch("https://api.azri.us/reports/save-user", {
          method: 'POST',
          body: JSON.stringify(user),
          headers: {
              'Content-Type': 'application/json'
          }
      }).then(res => res.json())
            .then(data => console.log(data))
            .catch(err => console.error("Error:", err));
  }


  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    console.log("useEffect");
    if(isLoaded == false) {
        fetch("https://api.azri.us/reports/get-reports")
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result["reports"]);
                    setIsLoaded(true);
                    setReports(result["reports"]);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.error(error)
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }

  });


  const classes = useStyles();

  if (isLoggedIn() === false ) {
    return (
      <FacebookLogin
          appId="754258275116869"
          autoLoad={false}
          fields="name,email,picture"
          scope="public_profile,email"
          callback={(response) => updateUserContext(response)}
      />
    )
  }
  else if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
      return <div>Loading...</div>;
  } else {
      return (
          <GridContainer>
              {reports.map(report => (
              <GridItem xs={12} sm={12} md={12} key={report.id}>

                  <Card key={report.id}>
                      <CardHeader color="info">

                          <h4 className={classes.cardTitleWhite}>{report.name}</h4>

                          <p className={classes.cardCategoryWhite}>
                              {report.description}

                              {isUserSubscribed(report.id) ? (
                                      <Checkbox
                                          color="default"
                                          label="Subscribed"
                                          checked={isUserSubscribed(report.id)}
                                          onClick={() => handleUnsubscribe(report.id)}
                                          key="Subscribed"/>
                                  ) :
                                  (
                                      <Button
                                          color="primary"
                                          onClick={() => handleSubscription(report.id)}
                                      >Subscribe</Button>
                                  )
                              }
                          </p>

                      </CardHeader>
                      <CardBody>
                          <Table
                              tableHeaderColor="info"
                              tableHead={report.head}
                              tableData={report.table}
                          />
                      </CardBody>
                  </Card>
              </GridItem>
                  ))}
          </GridContainer>

      );
  }
}
