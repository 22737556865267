import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// core components
import Quote from "components/Typography/Quote.js";
import Muted from "components/Typography/Muted.js";
import Primary from "components/Typography/Primary.js";
import Info from "components/Typography/Info.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {Link} from "react-router-dom";

const styles = {
    typo: {
        paddingLeft: "25%",
        marginBottom: "40px",
        position: "relative"
    },
    note: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        bottom: "10px",
        color: "#c0c1c2",
        display: "block",
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "13px",
        left: "0",
        marginLeft: "20px",
        position: "absolute",
        width: "260px"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);

export default function PrivacyPage() {
    const classes = useStyles();
    return (
        <GridContainer>
              <GridItem xs={12} sm={12} md={12} key="privacy">
                <Card>
                    <CardHeader color="info">
                        <h4 className={classes.cardTitleWhite}>Privacy Policy</h4>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <h3>Browsing Privacy</h3>
                        </div>
                        <div>
                            <p>
                                Our website uses cookies to ensure the content of our web pages performs properly and
                                delivers the information
                                you request to your browser. In using our website, you agree to allow cookies on your
                                browser. All data on
                                our website tracked through the Secure Socket Layer (SSL).
                            </p>
                        </div>
                        <div>
                            <h3>Information Collection</h3>
                        </div>
                        <div>
                            <p>
                                We collect the information of the data request forms as well as email receipt, read,
                                forward, spam reports,
                                unsubscribe requests. We use contact information provided to use via login to send our
                                reports to that email
                                address. We use user location information to build out default local geography filters
                                on the
                                reports offered on our platform.
                            </p>
                            <p>
                                In using our service, you additionally agree to the <a
                                href="https://aws.amazon.com/privacy/">AWS Privacy
                                policy</a>.
                            </p>
                        </div>
                        <div>
                            <h3>Use of Personal Information</h3>
                            <p>
                                We use your personal information to contact you to deliver request content. We do not
                                share any personal
                                information with 3rd parties.
                            </p>
                        </div>
                    </CardBody>
                </Card>

            </GridItem>
        </GridContainer>

    );
}
