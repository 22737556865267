import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// core components

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Link } from "react-router-dom";

import {UserConsumer, UserProvider} from "contexts/UserContext"
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";


const styles = {
    typo: {
        paddingLeft: "25%",
        marginBottom: "40px",
        position: "relative"
    },
    note: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        bottom: "10px",
        color: "#c0c1c2",
        display: "block",
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "13px",
        left: "0",
        marginLeft: "20px",
        position: "absolute",
        width: "260px"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);

export default function WelcomePage(props) {
    const {user, ...rest} = props;
    const classes = useStyles();
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12} key="welcome">
                <Card>
                    <CardHeader color="info">
                        <h4 className={classes.cardTitleWhite}>Welcome to Azrius Analytics</h4>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <h3>Our Mission</h3>
                        </div>
                        <div>
                            <p>
                                Welcome to Azrius Analytics. Our mission is to explore data warehousing, data science,
                                    and everything in between.
                            </p>
                        </div>
                        <div>
                            <h3>Our Publications</h3>
                        </div>
                        <div>
                            <p>
                                The dashboards and backend data pipelines on this site support articles on the following
                                publications
                            </p>
                            <p>
                                <ul>
                                <li>Talking to the Owl -&nbsp;
                                     <a target="_new"
                                       href="https://www.owlmountain.net/">https://www.owlmountain.net/</a></li>
                                    <li>Medium -&nbsp;
                                     <a target="_new"
                                       href="https://medium.com/@timburnsowlmtn">https://medium.com/@timburnsowlmtn</a></li>

                                </ul>
                            </p>
                        </div>
                        <div>
                            <h3>Our Stack</h3>
                        </div>
                        <div>
                            <p>
                                No secrets here!  Our stack is pure serverless AWS.

                            </p>
                            <p>
                                <ul>
                                    <li><b>Web Frontend</b> - Static React Material Design on S3,
                                        Courtesy of Creative Tim (not me, sadly)</li>
                                        <ul>
                                            <li><b>Backend API</b> - AWS API Gateway and Lambda Functions</li>
                                        </ul>
                                    <li><b>Data Pipelines</b> - AWS Step Functions</li>
                                    <ul>
                                        <li><b>Analytics Layer</b> - Pandas and SQL</li>
                                        <li><b>Data Warehouse</b> - Snowflake and/or Postgres (Always Evolving)</li>
                                        <li><b>Data Streaming</b> - SQS and Kafka</li>
                                    </ul>
                                </ul>

                            </p>
                            <p>If you have questions about any of these technologies, send me an email <a
                                href="mailto:support@azrius.zendesk.com">support@azrius.zendesk.com</a>&nbsp;
                                 or through <a target="_new" href="https://azrius.zendesk.com/">Zendesk</a>.

                            </p>

                        </div>

                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>

    );
}
