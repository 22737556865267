import React, {useEffect, useState} from "react";
// react plugin for creating charts

import ChartistGraph from "react-chartist";
// @material-ui/core
import {makeStyles} from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import Danger from "components/Typography/Danger.js";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";

import {DataGrid} from '@material-ui/data-grid'

import {Chart} from 'react-google-charts';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import {president_sources, senate_sources, house_sources, senate_rows, senate_columns, presidential_last_updated, senate_last_updated}
from "variables/general.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Table from "../../components/Table/Table";

const useStyles = makeStyles(styles);

export default function Dashboard() {
    const classes = useStyles();
    const [isLoaded, setIsLoaded] = useState(false);
    const [senatePolls, setSenatePolls] = useState([["State", "Value"]]);
    const [senateSummary, setSenateSummary] = useState([]);
    const [senateLastUpdated, setSenateLastUpdated] = useState([]);

    const [presidentPolls, setPresidentPolls] = useState([["State", "Value"]]);
    const [presidentSummary, setPresidentSummary] = useState([]);
    const [presidentLastUpdated, setPresidentLastUpdated] = useState([]);

    const [error, setError] = useState(null);

    useEffect(() => {
        // Update the document title using the browser API
        console.log("useEffect: " + isLoaded);
        if(isLoaded == false) {
            fetch("https://api.azri.us/reports/get-data/polls_2020")
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result["reports"]);
                        setIsLoaded(true);
                        setSenatePolls(result["dashboards"]["polls_2020"]["senate_polls"]);
                        setPresidentPolls(result["dashboards"]["polls_2020"]["president_polls"]);

                        let demCount = result["dashboards"]["polls_2020"]["senate_projection"]["DEM_SEATS"];
                        let repCount = result["dashboards"]["polls_2020"]["senate_projection"]["REP_SEATS"];
                        let pickupCount = result["dashboards"]["polls_2020"]["senate_projection"]["DEM_PICKUP"];
                        if (demCount > repCount) {
                            setSenateSummary("DEM " + demCount + " / REP " + repCount
                                + " (+" + pickupCount + " DEM)");
                        }
                        else {
                            setSenateSummary("REP " + repCount + " / DEM " + demCount
                                + " (+" + pickupCount + " DEM)")
                        }

                        demCount = result["dashboards"]["polls_2020"]["president_summary"]["DEM_COUNT"];
                        repCount = result["dashboards"]["polls_2020"]["president_summary"]["REP_COUNT"];
                        if (demCount > repCount) {
                            setPresidentSummary("DEM " + demCount + " / REP " + repCount)
                        }
                        else {
                            setPresidentSummary("REP " + repCount + " / DEM " + demCount)
                        }

                        setSenateLastUpdated(result["dashboards"]["polls_2020"]["senate_summary"]["LAST_UPDATED"])
                        setPresidentLastUpdated(result["dashboards"]["polls_2020"]["president_summary"]["LAST_UPDATED"])

                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        console.error(error)
                        setIsLoaded(true);
                        setError(error);
                    }
                )
    }

    });

    return (
        <div>
           <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Icon>person</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>Presidential Race 2020</p>
                            <h3 className={classes.cardTitle}>
                                <small>{presidentSummary}</small>
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <DateRange/>
                                Last Updated on {presidentLastUpdated}
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Icon>group</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>US Senate Race 2020</p>
                            <h3 className={classes.cardTitle}>
                                <small>{senateSummary}</small>

                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <DateRange/>
                                Last Updated on {senateLastUpdated}
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Icon>groups</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>US House Race 2020</p>
                            <h3 className={classes.cardTitle}>
                                <small>DEM 232 / REP 197</small>

                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <Danger>
                                <Warning/>
                            </Danger>
                            <a href="#houseSummary" onClick={e => e.preventDefault()}>
                                Update disabled
                            </a>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <Card>
                        <CardHeader color="info">
                            <CardIcon color="info">
                                <Icon>person</Icon>
                            </CardIcon>
                            <h4 className={classes.cardTitleWhite}>Presidential Race 2020</h4>
                            <p className={classes.cardCategoryWhite}>
                                Projected State Results based on 538 Data
                            </p>
                        </CardHeader>
                        <CardBody>
                            <Chart
                                width={'600px'}
                                height={'400px'}
                                chartType="GeoChart"
                                loader={<div>Loading Chart</div>}
                                data={presidentPolls}
                                options={{
                                    region: 'US',
                                    displayMode: 'regions',
                                    colorAxis: {colors: ['red', 'purple', 'blue'], values: [-15, 0, 15]},
                                           backgroundColor: '#81d4fa',
                                          datalessRegionColor: '#ccc',
                                          defaultColor: '#666',
                                    resolution: 'provinces',
                                }}
                                // Note: you will need to get a mapsApiKey for your project.
                                // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
                                mapsApiKey="AIzaSyC7elhAQ6lQFCp_HpWP2jMY_ryYtK9Zygw"
                                rootProps={{'data-testid': '2'}}
                            />
                        </CardBody>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <DateRange/>
                                Last Updated on {presidential_last_updated}
                            </div>
                        </CardFooter>
                    </Card>

                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Card>
                        <CardHeader color="info">
                            <CardIcon color="info">
                                <Icon>group</Icon>
                            </CardIcon>
                            <h4 className={classes.cardTitleWhite}>Senate Race 2020</h4>
                            <p className={classes.cardCategoryWhite}>
                                Battleground States from FiveThirtyEight Polls
                            </p>
                        </CardHeader>
                        <CardBody>
                            <Chart
                                width={'600px'}
                                height={'400px'}
                                chartType="GeoChart"
                                data={senatePolls}
                                options={{
                                    region: 'US',
                                    displayMode: 'regions',
                                    colorAxis: {colors: ['red', 'purple', 'blue'], values: [-15, 0, 15]},
                                           backgroundColor: '#81d4fa',
                                          datalessRegionColor: '#ccc',
                                          defaultColor: '#666',
                                    resolution: 'provinces',
                                }}
                                // Note: you will need to get a mapsApiKey for your project.
                                // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
                                mapsApiKey="AIzaSyC7elhAQ6lQFCp_HpWP2jMY_ryYtK9Zygw"
                                rootProps={{'data-testid': '2'}}
                            />
                        </CardBody>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <DateRange/>
                                Last Updated on {presidential_last_updated}
                            </div>
                        </CardFooter>
                    </Card>

                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <CustomTabs
                        title="Election Poll Sources:"
                        headerColor="info"
                        tabs={[
                            {
                                tabName: "Presidential",
                                tabContent: (
                                    <Tasks
                                        checkedIndexes={[0, 1]}
                                        tasksIndexes={[0, 1]}
                                        tasks={president_sources}
                                    />
                                )
                            },
                            {
                                tabName: "Senate",
                                tabContent: (
                                    <Tasks
                                        checkedIndexes={[1]}
                                        tasksIndexes={[0, 1, 2]}
                                        tasks={senate_sources}
                                    />
                                )
                            },
                            {
                                tabName: "House",
                                tabContent: (
                                    <Tasks
                                        checkedIndexes={[0]}
                                        tasksIndexes={[0, 1]}
                                        tasks={house_sources}
                                    />
                                )
                            }
                        ]}
                    />
                </GridItem>
            </GridContainer>
        </div>
    );
}
